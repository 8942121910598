.contact-form {
    box-sizing: border-box;
    margin: 0px auto;
    padding: 0px 10%;
    width: 100%;
}

.instr {
    padding-bottom: 20px;
}

.instr a {
    color: var(--experience-header-color);
    text-decoration: underline transparent;
}

.instr a:hover {
    text-decoration: underline;
}

.red {
    color: #e80000;
}

.form-item {
    box-sizing: border-box;
    padding-bottom: 20px;
}

.contact-form label {
    display: block;
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.contact-form input, .contact-form textarea {
    background-color: var(--contact-background-color);
    border: none;
    border-bottom: 3px solid #e0e0e0;
    color: var(--text-color);
    font-size: 16px;
    outline: none;
    resize: vertical;
    padding: 0px; /* Makes padding even on both sides. */
    transition: border-bottom 0.25s ease;
    width: 100%;
}

.contact-form input:focus {
    border-bottom: 3px solid var(--input-border-color);
}

.contact-form textarea:focus {
    border-bottom: 3px solid var(--input-border-color);
}

.btn {
    background-color: #021435;
    border: none;
    border-radius: 5px;
    display: inline-block;
    color: white;
    cursor: pointer;
    font-size: 18px;
    height: 60px;
    outline: none;
    text-align: center;
    transition: all 0.25s ease;
    width: 130px;
}

.btn:disabled {
    background-color: #34425D;
    cursor: default;
}

.btn:hover {
    /* background-color: #34425D; */
    opacity: 0.5;
}

.green {
    color: #228B22;
}

#message {
    padding: 0px 20px;
}