/* Default Style (Smartphone) */ 

nav {
    background-color: var(--nav-background-color);
    box-sizing: border-box; /* Fixes header to top. */
    height: 75px;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 10; 
}

nav * {
    font-family: 'Ubuntu', sans-serif;
    margin: 0px;
    padding: 0px;
}


.navbar-container {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 0px auto;
    padding: 20px 0px;
    width: 90%;
}

.logo {
    color: var(--text-color);
    font-size: 30px;
    font-weight: bold;
    letter-spacing: 2px;
    text-decoration: none;
    text-transform: uppercase;
}

.logo:hover {
    opacity: 0.5;
}

.toggle-button {
    align-items: center;
    cursor: pointer;
    display: inline-block;
    height: fit-content;
    transition: all 0.5s ease;
}

.toggle-button .bar {
    background-color: var(--text-color);
    border-radius: 2px;
    height: 4px;
    margin: 4px;
    transition: all 0.25s ease;
    width: 30px;
}

.toggle-button:hover > .bar {
    opacity: 0.5;
}

#nav-links {
    background-color: var(--nav-background-color);
    overflow: hidden;
    transition: all 0.5s ease;
    align-items: center;
}

/* Changes between collasped and open view. */
.nav-collasped {
    max-height: 0px;
}

.nav-open {
    max-height: 1000px;
}

#nav-links a {
    border: 1px solid var(--nav-border);
    color: var(--nav-link-color);
    display: block;
    font-size: 15px;
    font-weight: 500;
    margin: 10px auto;
    padding: 7.5px 0px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 90%;
}

#nav-links a:hover {
    color: var(--nav-link-color-hover);
    border: 1px solid var(--text-color);
}

.darkmode {
    border: 1px solid var(--nav-border);
    color: var(--nav-link-color);
    display: block;
    font-size: 15px;
    cursor: pointer;
    font-weight: 500;
    margin: 10px auto;
    padding: 7.5px 0px;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    width: 90%;
    white-space: nowrap;
    transition: all 0.5s ease;
}

.darkmode:hover {
    color: var(--nav-link-color-hover);
    border: 1px solid var(--text-color);
}

/* Responsive Mode */

@media screen and (min-width: 768px) {
    nav {
        align-items: center; 
        display: flex;
        justify-content: space-between;
        margin: 0px auto;
    }
    
    .toggle-button {
        display: none;
    }

    #nav-links {
        background-color: transparent;
        display: flex;
        height: auto;
        overflow: visible;
    }

    #nav-links a {
        border: 1px solid transparent;
        border-radius: 20px;
        margin: 0px 5px;
        padding: 5px 7.5px;
    }

    #nav-links a:hover {
        border: 1px solid var(--nav-link-color);
    }

    .darkmode {
        border: 1px solid transparent;
        border-radius: 20px;
        margin: 0px 5px;
        padding: 5px 7.5px;
    }

    .darkmode:hover {
        border: 1px solid var(--nav-link-color);
    }

    .nav-collasped {
        max-height: 1000px;
    }
}