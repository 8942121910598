/* Applies to everything */
* {
    font-family: 'Lato', sans-serif;
}

body.light {
    --background-color: white;
    --contact-background-color: #00000009;
    --experience-header-color: #3377cc;
    --experience-subheader-color: #325d7b;
    --footer-link-color-hover: black;
    --input-border-color: #325d7b;
    --nav-background-color: #f7f7f7;
    --nav-border: #999999;
    --nav-link-color-hover: black;
    --nav-link-color: #999999;
    --text-color-p: #333333;
    --text-color: black;
}

body.dark {
    --background-color: #212121;
    --contact-background-color: black;
    --experience-header-color: #8AB4F8;
    --experience-subheader-color: #BDC1C6;
    --footer-icon-filter: invert(1);
    --footer-link-opacity: 0.5;
    --input-border-color: #8AB4F8;
    --nav-background-color: black;
    --nav-border: transparent;
    --nav-link-color: white;
    --text-color-p: #BDC1C6;
    --text-color: white;
}

html, body {
    height: 100%;
    margin: 0px;
    background-color: var(--background-color);
    color: var(--text-color);
    transition: all 0.5s ease;
}

body {
    display: flex;
    flex-direction: column;
}

p {
    line-height: 1.45;
    color: var(--text-color-p);
}

a {
    transition: all 0.25s ease;
}

.container {
    box-sizing: border-box;
    flex: 1 0 auto;
    margin: 0px auto; /* Centers the container. Don't use with position: absolute. */
    width: 65%;
    padding-top: 75px; /* Brings the container below the NavBar. */
}

.container hr {
    border: 2px solid var(--text-color);
    background-color: var(--text-color);

}

.home-video {
    text-align: center;
    padding-bottom: 30px;
}

.home-video iframe {
    max-width: 100%;
}

.page-header {
    font-family: 'Montserrat', sans-serif;
    font-size: 40px;
    font-weight: 600;
    padding: 30px 0px;
}

/* Responsive Mode */

@media screen and (min-width: 1200px) {
    nav {
        padding: 0px 17.5%;
    }
}

@media only screen and (max-width: 1200px) and (min-width: 992px) {
    .container {
        width: 75%;
    }

    nav {
        padding: 0px 12.5%;
    }
}

@media only screen and (max-width: 992px) and (min-width: 768px) {
    .container {
        width: 82.5%;
    }

    nav {
        padding: 0px 7.5%;
    }
}

@media only screen and (max-width: 768px) {
    .container {
        width: 90%;
    }
}