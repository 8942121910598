.intro {
    display: flex;
    flex-direction: column;
    min-height: 250px;
    justify-content: center;
}

.intro p {
    margin-top: 0px;
    font-size: 17.5px;
}