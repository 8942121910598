.layout {
    padding: 0px 10%;
}

.profile-pic {
    margin: auto;
    display: block;
    width: 50%;
}

#blog {
    text-align: center;
}

.blog-entry {
    padding: 20px 0px;
}

.blog-entry-title {
    color: var(--experience-header-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    text-decoration: underline transparent;
}

.blog-entry-title:hover {
    text-decoration: underline;
}

.blog-entry-date {
    color: var(--experience-subheader-color);
    font-family: 'Montserrat', sans-serif;
    margin: 10px 0px;
}

.loading-show {
    visibility: visible;
}

.loading-hidden {
    visibility: hidden;
}

.MuiCircularProgress-colorPrimary {
    color: #8AB4F8 !important;
}

#center { 
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 20px 0px;
}