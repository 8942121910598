.exp-container {
    display: flex;
    flex-direction: row;
    padding: 0px 5%;
    padding-bottom: 30px;
}

.exp-header {
    font-family: 'Montserrat', sans-serif;
    font-size: 30px;
    font-weight: 600;
    margin: 30px 0px;
    text-align: center;
}

.exp-container img {
    align-self: start; /* Prevents the image from stretching vertically. */
    border-radius: 3px;
    display: block;
    width: 35%;
}

.description .header a {
    color: var(--experience-header-color);
    font-family: 'Montserrat', sans-serif;
    font-size: 22px;
    text-decoration: underline transparent;
}

.description .header a:hover {
    text-decoration: underline;
}

.description .sub-header {
    color: var(--experience-subheader-color);
    font-family: 'Montserrat', sans-serif;
    font-weight: bold;
    margin: 2.5px 0px;
}

.exp-container .description {
    line-height: 1.45;
    padding-left: 45px;
}

/* Responsive Mode */

@media only screen and (max-width: 730px) {
    .exp-container {
        flex-direction: column;
    }

    .exp-container img {
        margin: 0px auto;
        width: 75%;
    }

    .exp-container .description {
        margin: 0px auto;
        padding: 15px 0px;
        width: 75%;
    }
}

@media only screen and (max-width: 600px) {
    .exp-container img {
        width: 82.5%;
    }

    .exp-container .description {
        width: 82.5%;
    }
}

@media only screen and (max-width: 500px) {
    .exp-container img {
        width: 90%;
    }

    .exp-container .description {
        width: 90%;
    }
}