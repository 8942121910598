.footer {
    border-width: 100%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    font-size: 15px;
    font-weight: 400; /* Changes boldness */
    margin: 0px auto;
    padding: 10px 0px;
    text-align: center;
    width: 90%;
}

.icons img {
    display: inline-block;
    filter: var(--footer-icon-filter); /* Dark mode */
    opacity: 0.5;
    padding: 10px;
    transition: all 0.25s ease;
    width: 25px;
}

.icons img:hover {
    opacity: 1;
}

.footer-menu {
    padding: 0px 5px;
}

#footer-link a {
    color: var(--nav-link-color);
    text-decoration: none;
    opacity: var(--footer-link-opacity);
}

#footer-link a:hover {
    color: var(--footer-link-color-hover);
    opacity: 1;
}

#footer-darkmode {
    color: var(--nav-link-color);
    text-decoration: none;
    opacity: var(--footer-link-opacity);
    cursor: pointer;
    transition: all 0.25s ease;
}

#footer-darkmode:hover {
    color: var(--footer-link-color-hover);
    opacity: 1;
}

.footer-copyright {
    color: var(--text-color); /* Dark mode */
    margin: 10px 0px;
}